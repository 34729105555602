import { NavigateFunction } from "react-router-dom";

import { Icon, IconTypes, Select, Toggle } from "../../../../shared/components";
import { TableProps } from "../../../../shared/components/Table/types/TableProps";
import { theme } from "../../../../constants";
import { Badge } from "../../../../shared/styled";
import { Lead } from "../../../../models/lead";
import { parsePhoneNumber } from "../../../../models/lead/utilities";
import { PriorityMap } from "../../../../models/lead/priorities";
import { toReadableDateOnly } from "../../../../utilities/datetime";

const priorities: { [key: number]: string } = {};
for (const key in PriorityMap) {
  if (PriorityMap.hasOwnProperty(key)) {
    priorities[key] = PriorityMap[key].label;
  }
}

interface TableSchemaArgs {
  // toggleDetails: (id: number) => void | Promise<void>;
  // deleteLead: (id: number) => void | Promise<void>;
}

export const TableSchema = ({}: // toggleDetails,
// deleteLead,
TableSchemaArgs): TableProps<Lead>["columns"] => [
  {
    label: "Created At",
    attribute: "created_at",
    orderByAttribute: "created_at",
    styling: { dimensions: { maxWidth: "150px", width: "150px" } },
    render: (item) =>
      item?.created_at ? toReadableDateOnly(item?.created_at) : <>-</>,
  },
  {
    label: "Name",
    attribute: "first_name",
    orderByAttribute: "first_name",
    styling: { dimensions: { maxWidth: "200px", width: "200px" } },
    render: (item) => `${item?.first_name} ${item?.last_name}`,
  },
  {
    label: "Email",
    attribute: "email",
    orderByAttribute: "email",
    styling: { dimensions: { width: "200px", maxWidth: "200px" } },
  },
  {
    label: "Phone",
    attribute: "phone",
    styling: {
      dimensions: { maxWidth: "200px", width: "200px" },
    },
    render: (item) => parsePhoneNumber(item?.phone as string) || <>-</>,
  },
  {
    label: "Status",
    attribute: "priority",
    orderByAttribute: "priority",
    styling: { dimensions: { width: "100px", maxWidth: "100px" } },
    render: (item) => {
      if (item?.priority === null || item?.priority === undefined)
        return <Badge dimensions={{ width: "80%" }}>Not Claimed</Badge>;
      return (
        <Badge dimensions={{ width: "80%" }}>
          {priorities[item?.priority] !== undefined
            ? priorities[item?.priority]
            : "Unknown"}
        </Badge>
      );
    },
  },
  {
    label: "Claimed By",
    attribute: "agent",
    styling: { dimensions: { width: "200px", maxWidth: "200px" } },
    render: (item) =>
      item?.agent ? (
        <>
          {item?.agent?.first_name} {item?.agent?.last_name}
        </>
      ) : (
        <>Not Claimed</>
      ),
  },
  {
    label: "Provider",
    attribute: "provider_id",
    orderByAttribute: "provider_id",
    styling: { dimensions: { width: "200px", maxWidth: "200px" } },
    render: (item) =>
      item?.provider ? (
        <>
          {item?.provider.name} ({item?.provider?.code})
        </>
      ) : (
        <> - </>
      ),
  },
  //{
  //  label: "Action",
  //  styling: { dimensions: { width: "100px", maxWidth: "100px" } },
  //  render: (item) => (
  //    <>
  //      <Select
  //        transparent={true}
  //        placeholder={
  //          <Icon
  //            type={IconTypes.doner}
  //            color={theme.colors.disabled}
  //            size="18px"
  //          />
  //        }
  //        hasCarrot={false}
  //        options={[]}
  //        closeOnSelectOption={true}
  //        enableHoverEffect={true}
  //        justifyContent="center"
  //        onTop={true}
  //        style={{
  //          valueWrapper: {
  //            dimensions: { width: "120px", height: "32px" },
  //          },
  //        }}
  //        setValue={(value: string) => {
  //          const [type, data] = value?.split(",");
  //          if (type === "expand") {
  //            if (item?.id === undefined) return;
  //            // toggleDetails(item?.id);
  //            return;
  //          }
  //
  //          if (data === "delete") {
  //            if (item?.id !== undefined) {
  //              // deleteLead(item?.id)
  //            }
  //            return;
  //          }
  //        }}
  //      />
  //    </>
  //  ),
  //},
];
