import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  FlexRow,
  FlexColumn,
  Button,
  ButtonTypes,
  Text,
  TitleXL,
  Badge,
} from "../../../../shared/styled";
import { Scroll, Icon, IconTypes } from "../../../../shared/components";
import { LeadFilters } from "../components/LeadFilters";
import { Lead } from "../../../../models/lead";
import { httpGetLeads } from "../../../../apis/leads";
import { httpExportLeads } from "../../../../apis/admin/lead";
import { theme, colors } from "../../../../constants";
import { Toaster, toast } from "react-hot-toast";
import { Table } from "../../../../shared/components/Table";
import { TableHeader } from "../../../../constants/table/TableHeader";
import { TableFooter } from "../../../../constants/table/TableFooter";
import { PaginationStyling } from "../../../../constants/table/tools/PaginationStyling";
import { TableStyling } from "../../../../constants/table/TableStyling";
import { TableSchema } from "./TableSchema";
import { leadFilterHook } from "../components/LeadFilters/leadFilterHook";
import { css } from "styled-components";
import { AdminLeadDetails } from "./components/LeadDetails";

const LeadsTableStyle = {
  ...TableStyling,
  row: {
    ...TableStyling.row,
    dimensions: { height: "64px" },
    css: css`
      ${TableStyling?.row?.css};
      &:hover {
        box-shadow: rgb(182, 182, 182) 0px 0px 10px 4px;
        border-radius: 8px;
      }
    `,
  },
};

export const Leads = () => {
  const [leads, setLeads] = useState<Lead[]>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const initialFilters = leadFilterHook();

  const initialPage = useRef<number>(Number(searchParams.get("page") ?? "0"));

  const [filters, setFilters] = useState<any>(initialFilters);
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(initialPage.current * 10);
  const [total, setTotal] = useState<number>();
  const [order, setOrder] = useState<string>("-created_at");

  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  const [downloadingCsv, setDownloadingCsv] = useState<boolean>(false);

  const fetchLeads = useCallback(
    async (query: {
      filters: typeof filters;
      limit: typeof limit;
      offset: typeof offset;
      order: typeof order;
    }) => {
      if (isLoading) return;

      setLoading(true);
      try {
        const response = await httpGetLeads(
          query.offset,
          query.limit,
          query.order.split(","),
          query.filters
        );

        setLeads(response?.data.results);
        setTotal(response?.data.count);
      } catch (err) {}
      setLoading(false);
    },
    [isLoading]
  );

  const handleExportClick = async () => {
    try {
      const filtersToUse = filters;
      if ("priority__in" in filters) {
        filtersToUse.priority__in = `${filters.priority__in}`
          .split(",")
          .filter((item) => item && !isNaN(Number(item)))
          .map((item) => Number(item));
      }

      const response = await httpExportLeads(filtersToUse);
      if (response) {
        toast.success("Successfully exported leads.", {
          style: {
            border: `1px solid ${theme.colors.success}`,
            padding: "16px",
            boxShadow: "none",
            borderRadius: "24px",
          },
          iconTheme: {
            primary: `${theme.colors.success}`,
            secondary: `${theme.colors.clean}`,
          },
        });
        window.open(
          `${window.location.origin}/${response.data.file}`,
          "_blank"
        );
      } else {
        toast.error("Failed to export leads.", {
          style: {
            border: `1px solid ${theme.colors.danger}`,
            padding: "16px",
            boxShadow: "none",
            borderRadius: "24px",
          },
          iconTheme: {
            primary: `${theme.colors.danger}`,
            secondary: `${theme.colors.clean}`,
          },
        });
      }
    } catch (err) {
      console.log(err);
      toast.error("Failed to prepare document: ", {
        style: {
          border: `1px solid ${theme.colors.danger}`,
          padding: "16px",
          boxShadow: "none",
          borderRadius: "24px",
        },
        iconTheme: {
          primary: `${theme.colors.danger}`,
          secondary: `${theme.colors.clean}`,
        },
      });
    }
  };

  //Saving for later
  // const handleSelectedFields = (fields: string[]) => {
  //   setSelectedFields(fields);
  // };

  const toggleDetails = useCallback(
    (id: number) => {
      setExpandedRows(
        expandedRows.includes(id)
          ? expandedRows.filter((expandedId) => expandedId !== id)
          : [...expandedRows, id]
      );
    },
    [expandedRows]
  );

  // Initial lead fetch
  useEffect(() => {
    fetchLeads({ filters, limit, offset, order });
  }, [filters, limit, offset, order]);

  return (
    <>
      <Toaster position="bottom-left" reverseOrder={false} />
      <Scroll wrapperStyle={{ dimensions: { flex: 1 } }}>
        <FlexColumn>
          <FlexRow
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem",
              boxShadow: `0px 1px 3px 0px ${theme.shadow.color}`,
              borderRadius: "12px 12px 12px 12px",
            }}
            padding={"24px"}
          >
            <FlexColumn gap="24px">
              <TitleXL weight="bolder" size="24px">
                Bulk Action
              </TitleXL>
              <FlexRow gap="16px">
                <Button
                  border={{ radius: "8px" }}
                  styleType={ButtonTypes?.primary}
                  dimensions={{ height: "40px", width: "100%" }}
                  disabled={downloadingCsv}
                  padding={{ left: "16px", right: "16px" }}
                  onClick={() => handleExportClick()}
                  // openModal("SelectedFieldsModal", SelectedFieldsModal, {
                  //   selectedFieldsCallback: handleSelectedFields,
                  //   value: selectedFields,
                  //   title: "Select Fields to Export",
                  //   exportLeads: handleExportClick,
                  // })}
                >
                  <FlexRow gap="8px" alignItems="center">
                    <Icon
                      type={IconTypes.export}
                      color={colors.white.clean}
                      size="18px"
                    />
                    <Text color={colors.white.clean} size={"14px"}>
                      Export Leads
                    </Text>
                  </FlexRow>
                </Button>
                <Button
                  border={{ radius: "8px" }}
                  styleType={ButtonTypes?.primary}
                  disabled={true}
                  dimensions={{ height: "40px", width: "100%" }}
                  padding={{ left: "16px", right: "16px" }}
                >
                  <FlexRow gap="8px" alignItems="center">
                    <Icon
                      type={IconTypes.trash}
                      color={colors.white.clean}
                      size="18px"
                    />
                    <Text color={colors.white.clean} size={"14px"}>
                      Delete Leads
                    </Text>
                  </FlexRow>
                </Button>
              </FlexRow>
            </FlexColumn>
          </FlexRow>
          <Table<Lead>
            headerComponent={
              <TableHeader>
                <LeadFilters injectInUrl={true} setFilters={setFilters} />
              </TableHeader>
            }
            footerComponent={
              <TableFooter disabled={isLoading}>
                <FlexColumn alignContent="center" justifyContent="center">
                  <Text>Total: {total}</Text>
                </FlexColumn>
                <PaginationStyling
                  breakLabel="..."
                  nextLabel="next"
                  onPageChange={({ selected }) => {
                    setOffset(selected * limit);
                  }}
                  pageRangeDisplayed={3}
                  pageCount={
                    total
                      ? Math.round(total / limit) +
                        (total % limit === 0 ? 1 : 0)
                      : 1
                  }
                  previousLabel="previous"
                  initialPage={initialPage.current}
                  renderOnZeroPageCount={null}
                />
              </TableFooter>
            }
            order={{
              setOrder: (value) => setOrder(value ?? ""),
              currentOrder: order ?? "",
            }}
            style={LeadsTableStyle}
            pagination={{
              limit,
              setLimit,
              offset,
              setOffset,
              total: total ?? 0,
              itemsPerPageOptions: [10, 20, 50, 100],
            }}
            onRowClick={(item) => toggleDetails(item.id as number)}
            data={leads ?? []}
            columns={TableSchema({})}
            isLoading={isLoading}
            extraRows={[
              {
                render: (item) =>
                  expandedRows.includes(item?.id as number) && (
                    <AdminLeadDetails item={item} />
                  ),
              },
            ]}
          />
        </FlexColumn>
      </Scroll>
    </>
  );
};
