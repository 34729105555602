import { useEffect, useState, useRef, useMemo } from "react";
import { css, styled } from "styled-components";

import { theme, colors } from "../../../../../constants";
import {
  Button,
  ButtonTypes,
  FlexColumn,
  FlexItem,
  FlexRow,
  Image,
  Text,
  TitleXL,
} from "../../../../../shared/styled";
import {
  Icon,
  IconTypes,
  LoadingImage,
  Select,
  SelectOption,
} from "../../../../../shared/components";
import { StyledBaseInput } from "../../../../../shared/components/Input/StyledBaseInput";
import { useAuth, useModalProvider } from "../../../../../contexts";
import { httpAdminGetUsers, httpGetProviders } from "../../../../../apis/admin";
import { getAvatarUrl } from "../../../../../utilities/avatar";
import { User } from "../../../../../models/user";
import { LeadFilters as LeadFiltersInterface } from "../../../../../models/lead/types";
import { PriorityMap } from "../../../../../models/lead/priorities";
import { DateRangeFilterModal } from "../../../../../modals";
import {
  toQueryParamDate,
  toQueryParamDateTime,
  toReadableDateOnly,
} from "../../../../../utilities/datetime";
import { DateRangeValue } from "../../../../../shared/components/DateRange/types";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Provider } from "../../../../../models/provider";
import toast from "react-hot-toast";
import {
  SearchSelect,
  SelectOption as SeachSelectOption,
} from "../../../../../shared/components/Select/SearchSelect";

const LeadFiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  justify-content: space-between;

  box-sizing: border-box;
  padding: 16px 20px;

  * {
    transition: all 0.2s;
  }
`;

interface LeadFiltersProps {
  setFilters?: (filters: LeadFiltersInterface) => void;
  injectInUrl?: boolean;
}

export const LeadFilters = ({
  setFilters,
  injectInUrl = false,
}: LeadFiltersProps) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { openModal, closeModal } = useModalProvider();

  const [supportDataLoaded, setSupportDataLoaded] = useState<boolean>(false);
  const [allUsers, setAllUsers] = useState<User[]>();
  const [allProviders, setProviders] = useState<Provider[]>();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const cursorPositionRef = useRef(0);

  const [search, setSearch] = useState<string>(
    searchParams.get("search") ?? ""
  );
  const [createdAtRange, setCreatedAtRange] = useState<DateRangeValue>({
    start: searchParams.get("created_at__gte")
      ? new Date(searchParams.get("created_at__gte") as string)
      : undefined,
    end: searchParams.get("created_at__lte")
      ? new Date(searchParams.get("created_at__lte") as string)
      : undefined,
  });
  const [moveRange, setMoveRange] = useState<DateRangeValue>({
    start: searchParams.get("ship_date__gte")
      ? new Date(searchParams.get("ship_date__gte") as string)
      : undefined,
    end: searchParams.get("ship_date__lte")
      ? new Date(searchParams.get("ship_date__lte") as string)
      : undefined,
  });
  const [priority, setPriority] = useState<number[] | undefined>(
    searchParams.get("priority__in")
      ? (searchParams.get("priority__in") as string)
          .split(",")
          .map((item) => Number(item))
      : undefined
  );
  const [claimedByAgent, setClaimedByAgent] = useState<number>(
    searchParams.get("agent__id")
      ? Number(searchParams.get("agent__id") as string)
      : -1
  );
  console.log("INIT", searchParams.get("agent__id"));
  const [selectedProvider, setSelectedProvider] = useState<number>(
    searchParams.get("provider_id")
      ? Number(searchParams.get("provider_id") as string)
      : -1
  );

  const isAdmin = useMemo(
    () =>
      currentUser?.roles?.find((role) => role?.includes("Admin"))
        ? true
        : false,
    [currentUser]
  );

  const fetchProviders = async () =>
    httpGetProviders()
      .then((res) => res.data as Provider[])
      .then((data) => setProviders(data))
      .catch((err) => {
        console.log(err);
        toast.error("Failed to load providers!");
      });

  const fetchUsers = async () =>
    httpAdminGetUsers()
      .then((res) => res.data)
      .then((data) => setAllUsers(data as User[]))
      .catch((err) => {
        //TODO Error handling
        console.log(err);
      });

  const loadSupportData = async () => {
    await Promise.all([fetchUsers(), fetchProviders()]);
    setSupportDataLoaded(true);
  };

  const agent__id = useMemo(
    () => (claimedByAgent === -1 ? undefined : claimedByAgent),
    [claimedByAgent]
  );
  const provider_id = useMemo(
    () => (selectedProvider === -1 ? undefined : selectedProvider),
    [selectedProvider]
  );
  const created_at__gte = useMemo(
    () =>
      createdAtRange?.start && createdAtRange?.end
        ? toQueryParamDateTime(createdAtRange?.start, false)
        : undefined,
    [createdAtRange]
  );
  const created_at__lte = useMemo(
    () =>
      createdAtRange?.start && createdAtRange?.end
        ? toQueryParamDateTime(createdAtRange?.end, true)
        : undefined,
    [createdAtRange]
  );
  const ship_date__gte = useMemo(
    () =>
      moveRange?.start && moveRange?.end
        ? toQueryParamDate(moveRange?.start)
        : undefined,
    [moveRange]
  );
  const ship_date__lte = useMemo(
    () =>
      moveRange?.start && moveRange?.end
        ? toQueryParamDate(moveRange?.end)
        : undefined,
    [moveRange]
  );

  const priority__in = useMemo(
    () => (priority && 0 < priority.length ? priority.join(",") : undefined),
    [priority]
  );

  const filterset = useMemo(
    () => ({
      search,
      priority__in,
      agent__id,
      created_at__gte,
      created_at__lte,
      ship_date__gte,
      ship_date__lte,
      provider_id,
    }),
    [
      search,
      priority__in,
      agent__id,
      created_at__gte,
      created_at__lte,
      ship_date__gte,
      ship_date__lte,
      provider_id,
    ]
  );

  useEffect(() => {
    injectInUrl &&
      navigate(
        `${window.location.pathname}?${Object.keys(filterset)
          .filter((key) => (filterset as any)[key] !== undefined)
          .map((key) => `${key}=${(filterset as any)[key]}`)
          .join("&")}`
      );
  }, [filterset, injectInUrl]);

  useEffect(() => {
    setFilters && setFilters(filterset);
  }, [filterset, setFilters]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.setSelectionRange(
        cursorPositionRef.current,
        cursorPositionRef.current
      );
    }
  }, [search]);

  const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
    const inputValue = (event.currentTarget as HTMLInputElement).value;
    const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9@.,\s]/g, "");

    cursorPositionRef.current =
      (event.currentTarget as HTMLInputElement).selectionStart || 0;

    setSearch(sanitizedValue);
  };

  const selectSearchValues: SeachSelectOption[] = useMemo(() => {
    return (allUsers ?? [])?.map((user) => {
      const avatarUrl = getAvatarUrl(
        user?.first_name || "Unknown",
        user?.last_name || "Unknown",
        `${user?.id}`
      );

      return {
        value: user?.id,
        searchValue: `${user?.first_name} ${user?.last_name}`,
        label: (
          <FlexRow
            alignItems="center"
            justifyContent="space-between"
            key={`agentOption-${user?.id}`}
            gap="5px"
            dimensions={{ width: "100%" }}
          >
            <Image
              padding="2px"
              background={colors.white.clean}
              border={{
                radius: "50%",
                width: "2px",
                color: theme.colors.primary,
              }}
              src={avatarUrl}
              dimensions={{ width: "20px", height: "20px" }}
            />
            <Text>
              {user?.first_name} {user?.last_name}
            </Text>
          </FlexRow>
        ),
      };
    });
  }, [allUsers]);

  const providerSelectSearchValues: SeachSelectOption[] = useMemo(() => {
    return (allProviders ?? [])?.map((provider) => {
      return {
        value: provider?.id,
        searchValue: provider.name,
        label: (
          <FlexRow
            alignItems="center"
            justifyContent="space-between"
            key={`providerOption-${provider?.id}`}
            gap="5px"
            dimensions={{ width: "100%" }}
          >
            <Text>{provider.name}</Text>
          </FlexRow>
        ),
      };
    });
  }, [allProviders]);

  useEffect(() => {
    loadSupportData();
  }, []);

  return (
    <LeadFiltersWrapper>
      <FlexColumn gap="16px">
        <FlexRow gap="10px">
          {Object.keys(PriorityMap).map((priorityKey) => (
            <Button
              border={{
                color:
                  priority && priority.includes(Number(priorityKey))
                    ? theme.font.color
                    : "white",
              }}
              background={PriorityMap[priorityKey as any]?.color}
              dimensions={{ width: "28px", height: "28px" }}
              onClick={() => {
                if (priority && priority.includes(Number(priorityKey))) {
                  setPriority(
                    priority.filter((p) => p !== Number(priorityKey))
                  );
                } else {
                  setPriority([...(priority || []), Number(priorityKey)]);
                }
              }}
            />
          ))}
        </FlexRow>
        <FlexRow gap="10px">
          {supportDataLoaded && (
            <>
              <SearchSelect
                options={selectSearchValues}
                defaultValue={claimedByAgent}
                onChange={(value) => setClaimedByAgent(value?.value as number)}
                placeholder="SELECT AGENT"
                isClearable={true}
                isSearchable={true}
                transparent={true}
                iconColor={theme.colors.primary}
              />
              <SearchSelect
                options={providerSelectSearchValues}
                defaultValue={selectedProvider}
                onChange={(value) =>
                  setSelectedProvider(value?.value as number)
                }
                placeholder="SELECT PROVIDER"
                isClearable={true}
                isSearchable={true}
                transparent={true}
                iconColor={theme.colors.primary}
              />
            </>
          )}
        </FlexRow>
      </FlexColumn>
      <FlexColumn gap="10px" dimensions={{ minWidth: "280px" }}>
        <StyledBaseInput
          placeholder="Search"
          dimensions={{ width: "100%", minHeight: "32px" }}
          type="text"
          value={search}
          onInput={handleInput}
          css={css`
            box-sizing: border-box;
          `}
        />
        <FlexRow justifyContent="space-between" alignItems="center" gap="8px">
          <Button
            border={{ color: colors?.grey?.geyser, radius: "8px" }}
            styleType={ButtonTypes?.clean}
            dimensions={{ height: "32px", width: "100%" }}
            onClick={() =>
              openModal("createdAtLeadFilterModal", DateRangeFilterModal, {
                setValue: setCreatedAtRange,
                value: createdAtRange,
                close: () => closeModal("createdAtLeadFilterModal"),
                title: <TitleXL>Created at Range</TitleXL>,
              })
            }
          >
            <FlexRow
              justifyContent="space-between"
              alignItems="center"
              gap="5px"
            >
              <Icon
                type={IconTypes?.calendar}
                size="20px"
                color={colors?.grey?.tundora}
              />
              <FlexItem dimensions={{ flex: 1 }}>
                <Text
                  align="center"
                  color={colors?.grey?.tundora}
                  weight="lighter"
                >
                  {(!createdAtRange?.start || !createdAtRange?.end) && (
                    <>Created Range</>
                  )}
                  {createdAtRange?.start && createdAtRange?.end && (
                    <>
                      {toReadableDateOnly(createdAtRange?.start)} -{" "}
                      {toReadableDateOnly(createdAtRange?.end)}
                    </>
                  )}
                </Text>
              </FlexItem>
            </FlexRow>
          </Button>
          <Button
            styleType={ButtonTypes?.clean}
            border={{ color: colors?.grey?.geyser, radius: "8px" }}
            dimensions={{ height: "32px", width: "100%" }}
            onClick={() =>
              openModal("shipmentAtLeadFilterModal", DateRangeFilterModal, {
                setValue: setMoveRange,
                value: moveRange,
                close: () => closeModal("shipmentAtLeadFilterModal"),
                title: <TitleXL>Move Range</TitleXL>,
              })
            }
          >
            <FlexRow alignItems="center">
              <Icon
                type={IconTypes?.calendar}
                size="20px"
                color={colors?.grey?.tundora}
              />
              <FlexItem dimensions={{ flex: 1 }}>
                <Text
                  align="center"
                  color={colors?.grey?.tundora}
                  weight="lighter"
                >
                  {(!moveRange?.start || !moveRange?.end) && <>Moving Range</>}
                  {moveRange?.start && moveRange?.end && (
                    <>
                      {toReadableDateOnly(moveRange?.start)} -{" "}
                      {toReadableDateOnly(moveRange?.end)}
                    </>
                  )}
                </Text>
              </FlexItem>
            </FlexRow>
          </Button>
        </FlexRow>
      </FlexColumn>
    </LeadFiltersWrapper>
  );
};
