import { NavigateFunction } from "react-router-dom";
import { TableProps } from "../../../../../../shared/components/Table/types/TableProps";
import { Provider } from "../../../../../../models/provider";

type ItemType = { [key: string]: string | number };

export const AgentByProviderStatisticsTableSchema = (
  navigate: NavigateFunction,
  providers: Provider[],
  createdAtRangeQuery: string
): TableProps<ItemType>["columns"] => [
  {
    label: "Agent Name",
    styling: { dimensions: { maxWidth: "200px", width: "200px" } },
    render: (item) => item?.agent_name,
    onClick: (item) => navigate(`/admin/leads?agent__id=${item?.agent_id}&${createdAtRangeQuery}`),
  },
  ...providers.map((provider) => ({
    label: provider.name,
    styling: { dimensions: { flex: 1 } },
    orderByAttribute: provider?.code,
    render: (item?: ItemType) => item?.[provider?.code] ?? <>-</>,
    onClick: (item?: ItemType) =>
      navigate(
        `/admin/leads?agent__id=${item?.agent_id}&provider_id=${provider?.id}&${createdAtRangeQuery}`
      ),
  })),
];
