import { useCallback, useEffect, useMemo, useState } from "react";
import { LeadProviderStatistics } from "../../../../../models/statistics";
import { theme } from "../../../../../constants";
import {
  Button,
  ButtonTypes,
  FlexColumn,
  FlexItem,
  FlexRow,
  Text,
  TitleXL,
} from "../../../../../shared/styled";
import { getLastMonday } from "../utilities";
import { DateRangeValue } from "../../../../../shared/components/DateRange/types";
import { httpAdminGetLeadProviderStatistics } from "../../../../../apis/admin";
import {
  toQueryParamDateTime,
  toReadableDateOnly,
} from "../../../../../utilities/datetime";
import toast from "react-hot-toast";
import { useModalProvider } from "../../../../../contexts";
import { DateRangeFilterModal } from "../../../../../modals";
import { Icon, IconTypes } from "../../../../../shared/components";
import { Table } from "../../../../../shared/components/Table";
import { TableHeader } from "../../../../../constants/table/TableHeader";
import { TableFooter } from "../../../../../constants/table/TableFooter";
import { LeadProviderStatisticsTableSchema } from "./schemes/LeadProviderSchema";
import { StatisticsTableStyling } from "./TableStyling";
import { useNavigate } from "react-router-dom";

export const ProviderTable = () => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModalProvider();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<string>("");

  const [createdAtRange, setCreatedAtRange] = useState<DateRangeValue>({
    start: getLastMonday(),
    end: new Date(),
  });

  const [data, setData] = useState<LeadProviderStatistics[]>([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    if (!createdAtRange?.start || !createdAtRange?.end) {
      setLoading(false);
      return;
    }

    try {
      const leadResponse = await httpAdminGetLeadProviderStatistics(
        undefined,
        undefined,
        order,
        createdAtRange?.start && createdAtRange?.end
          ? toQueryParamDateTime(createdAtRange.start)
          : undefined,
        createdAtRange?.start && createdAtRange?.end
          ? toQueryParamDateTime(createdAtRange.end, true)
          : undefined
      );

      const preparedData = (
        leadResponse?.data as LeadProviderStatistics[]
      ).filter(
        (statistic) =>
          statistic.total_leads !== undefined && statistic.total_leads > 0
      );

      const totalData = preparedData.reduce(
        (acc, stats) => {
          acc.total_leads = (acc.total_leads ?? 0) + (stats.total_leads ?? 0);
          acc.claimed_value =
            (acc.claimed_value ?? 0) + (stats.claimed_value ?? 0);
          acc.bad_value = (acc.bad_value ?? 0) + (stats.bad_value ?? 0);
          acc.future_value =
            (acc.future_value ?? 0) + (stats.future_value ?? 0);
          acc.hot_value = (acc.hot_value ?? 0) + (stats.hot_value ?? 0);
          acc.potential_value =
            (acc.potential_value ?? 0) + (stats.potential_value ?? 0);
          acc.booked_value =
            (acc.booked_value ?? 0) + (stats.booked_value ?? 0);
          return acc;
        },
        {
          lead_provider: { index: 0, name: "TOTAL" },
          total_leads: 0,
          claimed_value: 0,
          bad_value: 0,
          future_value: 0,
          hot_value: 0,
          potential_value: 0,
          booked_value: 0,
        } as LeadProviderStatistics
      );

      setData([...preparedData, totalData]);
      setLoading(false);
    } catch (error) {
      toast.error("Error fetching Lead Providers", {
        style: {
          border: `1px solid ${theme.colors.danger}`,
          padding: "16px",
          boxShadow: "none",
          borderRadius: "24px",
        },
        iconTheme: {
          primary: `${theme.colors.danger}`,
          secondary: `${theme.colors.clean}`,
        },
      });
      console.error(error);
    }
  }, [order, createdAtRange, isLoading]);

  useEffect(() => {
    fetchData();
  }, [order, createdAtRange]);

  const createdAtRangeQuery = useMemo(() => {
    if (!createdAtRange?.start || !createdAtRange?.end) return "";
    return `created_at__gte=${toQueryParamDateTime(
      createdAtRange?.start
    )}&created_at__lte=${toQueryParamDateTime(createdAtRange?.end)}`;
  }, [createdAtRange]);

  return (
    <Table<LeadProviderStatistics>
      order={{
        currentOrder: order,
        setOrder: (freshOrder) => setOrder(freshOrder ?? ""),
      }}
      headerComponent={
        <TableHeader>
          <FlexColumn dimensions={{ width: "100%" }} gap="10px">
            <FlexRow justifyContent="flex-start">
              <TitleXL>AGENT STATISTICS</TitleXL>
            </FlexRow>
            <FlexRow justifyContent="flex-end">
              <Button
                styleType={ButtonTypes?.primary}
                dimensions={{ height: "32px" }}
                disabled={isLoading}
                onClick={() =>
                  openModal("createdAtProviderModal", DateRangeFilterModal, {
                    setValue: setCreatedAtRange,
                    value: createdAtRange,
                    close: () => closeModal("createdAtProviderModal"),
                    title: <TitleXL>Created At Range</TitleXL>,
                  })
                }
              >
                <FlexRow
                  justifyContent="space-between"
                  alignItems="center"
                  gap="5px"
                >
                  <Icon
                    type={IconTypes?.calendar}
                    size="20px"
                    color={theme?.colors?.clean}
                  />
                  <FlexItem dimensions={{ flex: 1 }}>
                    <Text color={theme.colors?.clean} align="center">
                      {(!createdAtRange?.start || !createdAtRange?.end) && (
                        <>Created At Range</>
                      )}
                      {createdAtRange?.start && createdAtRange?.end && (
                        <>
                          {toReadableDateOnly(createdAtRange?.start)} -{" "}
                          {toReadableDateOnly(createdAtRange?.end)}
                        </>
                      )}
                    </Text>
                  </FlexItem>
                </FlexRow>
              </Button>
            </FlexRow>
          </FlexColumn>
        </TableHeader>
      }
      footerComponent={<TableFooter />}
      style={StatisticsTableStyling}
      data={data}
      columns={LeadProviderStatisticsTableSchema(navigate, createdAtRangeQuery)}
    />
  );
};
