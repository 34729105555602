import { css } from "styled-components";
import { TableStyling } from "../../../../../constants/table/TableStyling";
import { TablePropsStyle } from "../../../../../shared/components/Table/types/TableStyleProps";

export const StatisticsTableStyling: TablePropsStyle = {
  ...TableStyling,
  row: {
    ...TableStyling.row,
    css: css``,
  },
  headerCell: {
    css: css`justify-content: center;`
  },
  cell: {
    dimensions: {
      height: '100%'
    },
    css: css`
      cursor: pointer;
      &:hover {
        box-shadow: rgb(182, 182, 182) 0px 0px 10px 4px;
        border-radius: 8px;
      };

      height: 100%;
      justify-content: center;
      align-items: center;

      &:first-child {
        justify-content: center;
      }
    `,
  },
};
