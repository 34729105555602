import ReactPaginate from "react-paginate";
import styled from "styled-components";
import { theme } from "../../theme";
import { colors } from "../../colors";

export const PaginationStyling = styled(ReactPaginate)`
  display: flex;
  width: 100%;
  max-height: 32px;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;

  li {
    display: block;
    margin: 0 2.5px;
    cursor: pointer;

    a {
      background-color: ${colors.white.clean};

      border: solid 1px ${theme.colors.disabled};
      border-width: 1px;
      border-radius: 4px;

      padding: 4px;

      color: ${theme.colors.secondary};
      font-weight: 500;
    }

    &.selected {
      a {
        background-color: ${theme.colors.primary};
        color: ${colors.white.clean};
        border-color: ${colors.white.clean};
      }
    }

    &.disabled {
        a {
            opacity: 0.7;
            cursor: default;
        }
    }
  }
`;
