import { useCallback, useEffect, useMemo, useState } from "react";
import { useModalProvider } from "../../../../../contexts";
import { getLastMonday } from "../utilities";
import { DateRangeValue } from "../../../../../shared/components/DateRange/types";
import { AgentStatistics } from "../../../../../models/statistics";
import { ColumnItem } from "../../components/Table";
import { theme } from "../../../../../constants";
import {
  Button,
  ButtonTypes,
  FlexColumn,
  FlexItem,
  FlexRow,
  Text,
  TitleXL,
} from "../../../../../shared/styled";
import { DateRangeFilterModal } from "../../../../../modals";
import { Icon, IconTypes } from "../../../../../shared/components";
import {
  toQueryParamDateTime,
  toReadableDateOnly,
} from "../../../../../utilities/datetime";
import {
  httpAdminGetAgentProviderStatistics,
  httpAdminGetAgentProviderStatisticsResponseItem,
  httpGetProviders,
} from "../../../../../apis/admin";
import toast from "react-hot-toast";
import { Table } from "../../../../../shared/components/Table";
import { TableHeader } from "../../../../../constants/table/TableHeader";
import { TableFooter } from "../../../../../constants/table/TableFooter";
import { StatisticsTableStyling } from "./TableStyling";
import { Provider } from "../../../../../models/provider";
import { AgentByProviderStatisticsTableSchema } from "./schemes/AgentByProviderStatistics";
import { useNavigate } from "react-router-dom";

export const AgentProviderTable = () => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModalProvider();

  const [isReady, setReady] = useState<boolean>(false);

  const [providers, setProviders] = useState<Provider[]>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const [order, setOrder] = useState<string>();
  const [createdAtRange, setCreatedAtRange] = useState<DateRangeValue>({
    start: getLastMonday(),
    end: new Date(),
  });

  const [ignoreEmptyProviders, setIgnoreEmptyProviders] =
    useState<boolean>(false);
  const [ignoreEmptyAgents, setIgnoreEmptyAgents] = useState<boolean>(false);

  const [rawData, setRawData] =
    useState<httpAdminGetAgentProviderStatisticsResponseItem[]>();

  const fetchData = useCallback(async () => {
    setLoading(true);
    if (!createdAtRange?.start || !createdAtRange?.end) {
      setLoading(false);
      return;
    }
    try {
      const { data: freshData } = await httpAdminGetAgentProviderStatistics(
        createdAtRange?.start && createdAtRange?.end
          ? toQueryParamDateTime(createdAtRange.start)
          : undefined,
        createdAtRange?.start && createdAtRange?.end
          ? toQueryParamDateTime(createdAtRange.end, true)
          : undefined
      );

      setRawData(freshData);

      setLoading(false);
    } catch (error) {
      toast.error("Error fetching Agents", {
        style: {
          border: `1px solid ${theme.colors.danger}`,
          padding: "16px",
          boxShadow: "none",
          borderRadius: "24px",
        },
        iconTheme: {
          primary: `${theme.colors.danger}`,
          secondary: `${theme.colors.clean}`,
        },
      });
      console.error(error);
    }
  }, [createdAtRange]);

  const fetchProviders = useCallback(async () => {
    httpGetProviders()
      .then((res) => res.data as Provider[])
      .then((data) => setProviders(data))
      .catch((err) => {
        toast.error(`Failed to fetch providers list\n${err}`, {
          style: {
            border: `1px solid ${theme.colors.danger}`,
            padding: "16px",
            boxShadow: "none",
            borderRadius: "24px",
          },
          iconTheme: {
            primary: `${theme.colors.danger}`,
            secondary: `${theme.colors.clean}`,
          },
        });
      })
      .finally(() => setReady(true));
  }, []);

  useEffect(() => {
    if (isReady) fetchData();
  }, [isReady, order, createdAtRange]);

  useEffect(() => {
    fetchProviders();
  }, []);

  const filteredProviders = useMemo(() => {
    if (!providers || !rawData) return [];
    if (!ignoreEmptyProviders) return providers;
    return providers.filter((provider) => {
      const relativeRawData = rawData
        .map((rawItem) => rawItem.providers?.[provider.code] ?? 0)
        .map((item) => item as number)
        .reduce((a, b) => a + b, 0);

      return 0 < relativeRawData;
    });
  }, [providers, ignoreEmptyProviders, rawData]);

  const data = useMemo(() => {
    if (!rawData) return [];
    const mappedData = rawData.map((rawItem) => ({
      agent_id: rawItem?.user.id,
      agent_name: `${rawItem.user.first_name} ${rawItem.user.last_name}`,
      ...rawItem.providers,
    }));

    return !ignoreEmptyAgents
      ? mappedData
      : mappedData.filter((mappedItem) => {
          const relativeValues = Object.keys(mappedItem)
            .map((key) => {
              if (["agent_id", "agent_name"].includes(key)) return 0;
              return (mappedItem as any)?.[key] ?? 0;
            })
            .reduce((a, b) => a + b, 0);

          return 0 < relativeValues;
        });
  }, [rawData, ignoreEmptyAgents]);

  const createdAtRangeQuery = useMemo(() => {
    if (!createdAtRange?.start || !createdAtRange?.end) return "";
    return `created_at__gte=${toQueryParamDateTime(
      createdAtRange?.start
    )}&created_at__lte=${toQueryParamDateTime(createdAtRange?.end)}`;
  }, [createdAtRange]);

  const schema = useMemo(() => {
    if (!filteredProviders) return [];
    return AgentByProviderStatisticsTableSchema(
      navigate,
      filteredProviders,
      createdAtRangeQuery
    );
  }, [filteredProviders, createdAtRangeQuery]);

  const orderedData = useMemo(() => {
    const splitOrder = order
      ?.split(",")
      .map((orderItem) => ({
        asc: orderItem[0] !== "-",
        attribute: orderItem.replaceAll("-", ""),
      }))
      .reverse();

    let orderedItems = data;
    splitOrder?.forEach((orderItem) => {
      orderedItems = orderedItems.sort((a: any, b: any) =>
        orderItem.asc
          ? a[orderItem?.attribute] - b[orderItem?.attribute]
          : b[orderItem?.attribute] - a[orderItem?.attribute]
      );
    });
    return orderedItems;
  }, [data, order]);

  return (
    <Table<{ [key: string]: string | number }>
      order={{
        currentOrder: order ?? "",
        setOrder: (freshOrder) => setOrder(freshOrder ?? ""),
      }}
      headerComponent={
        <TableHeader>
          <FlexColumn dimensions={{ width: "100%" }} gap="10px">
            <FlexRow justifyContent="flex-start">
              <TitleXL>AGENT BY PROVIDER STATISTICS</TitleXL>
            </FlexRow>
            <FlexRow gap="10px" justifyContent="flex-end">
              <Button
                styleType={ButtonTypes?.primary}
                dimensions={{ height: "32px" }}
                disabled={isLoading}
                onClick={() =>
                  openModal(
                    "createdAtAgentStatisticsModal",
                    DateRangeFilterModal,
                    {
                      setValue: setCreatedAtRange,
                      value: createdAtRange,
                      close: () => closeModal("createdAtAgentStatisticsModal"),
                      title: <TitleXL>Created At Range</TitleXL>,
                    }
                  )
                }
              >
                <FlexRow
                  justifyContent="space-between"
                  alignItems="center"
                  gap="5px"
                >
                  <Icon
                    type={IconTypes?.calendar}
                    size="20px"
                    color={theme?.colors?.clean}
                  />
                  <FlexItem dimensions={{ flex: 1 }}>
                    <Text color={theme?.colors?.clean} align="center">
                      {(!createdAtRange?.start || !createdAtRange?.end) && (
                        <>Created At Range</>
                      )}
                      {createdAtRange?.start && createdAtRange?.end && (
                        <>
                          {toReadableDateOnly(createdAtRange?.start)} -{" "}
                          {toReadableDateOnly(createdAtRange?.end)}
                        </>
                      )}
                    </Text>
                  </FlexItem>
                </FlexRow>
              </Button>
            </FlexRow>
            <FlexRow gap="10px" justifyContent="flex-end">
              <Button
                styleType={
                  !ignoreEmptyProviders
                    ? ButtonTypes?.primary
                    : ButtonTypes?.primaryReverse
                }
                dimensions={{ height: "32px" }}
                disabled={isLoading}
                onClick={() => setIgnoreEmptyProviders(!ignoreEmptyProviders)}
              >
                {!ignoreEmptyProviders ? (
                  <>Ignore empty providers</>
                ) : (
                  <>Show empty providers</>
                )}
              </Button>
              <Button
                styleType={
                  !ignoreEmptyAgents
                    ? ButtonTypes?.primary
                    : ButtonTypes?.primaryReverse
                }
                dimensions={{ height: "32px" }}
                disabled={isLoading}
                onClick={() => setIgnoreEmptyAgents(!ignoreEmptyAgents)}
              >
                {!ignoreEmptyAgents ? (
                  <>Ignore empty agents</>
                ) : (
                  <>Show empty agents</>
                )}
              </Button>
            </FlexRow>
          </FlexColumn>
        </TableHeader>
      }
      footerComponent={<TableFooter />}
      style={StatisticsTableStyling}
      data={orderedData ?? []}
      columns={schema}
      isLoading={isLoading}
    />
  );
};
