import { FlexColumn, FlexRow, Text } from "../../../../../shared/styled";
import { colors, theme } from "../../../../../constants";
import { Lead } from "../../../../../models/lead";
import { OperableType } from "../../../../../models/lead/vehicle";
import { toReadableDate } from "../../../../../utilities/datetime";

export const AdminLeadDetails = ({ item }: { item: Lead }) => {
  return (
    <FlexRow padding={{ left: "64px", right: "64px" }}>
      <FlexRow
        dimensions={{ width: "100%" }}
        background={{ color: `${colors.white.gallery}` }}
        border={{ radius: "16px" }}
        padding={{
          top: "16px",
          bottom: "16px",
          left: "32px",
          right: "32px",
        }}
      >
        <FlexColumn
          dimensions={{ flex: 1 }}
          justifyContent="space-between"
          gap="8px"
        >
          <FlexColumn gap="4px">
            <Text
              size={`${theme.font.size.xxl}px`}
              color={`${theme.colors.primary}`}
            >
              Origin City
            </Text>
            <Text size={`${theme.font.size.xxl}px`}>{item?.origin_city}</Text>
          </FlexColumn>
          <FlexColumn gap="4px">
            <Text
              size={`${theme.font.size.xxl}px`}
              color={`${theme.colors.primary}`}
            >
              Origin Country
            </Text>
            <Text size={`${theme.font.size.xxl}px`}>
              {item?.origin_country}
            </Text>
          </FlexColumn>
          <FlexColumn gap="4px">
            <Text
              size={`${theme.font.size.xxl}px`}
              color={`${theme.colors.primary}`}
            >
              Origin Postal Code
            </Text>
            <Text size={`${theme.font.size.xxl}px`}>
              {item?.origin_postal_code}
            </Text>
          </FlexColumn>
          <FlexColumn gap="4px">
            <Text
              size={`${theme.font.size.xxl}px`}
              color={`${theme.colors.primary}`}
            >
              Origin Postal State
            </Text>
            <Text size={`${theme.font.size.xxl}px`}>{item?.origin_state}</Text>
          </FlexColumn>
        </FlexColumn>
        <FlexColumn
          dimensions={{ flex: 1 }}
          justifyContent="space-between"
          gap="8px"
        >
          <FlexColumn gap="4px">
            <Text
              size={`${theme.font.size.xxl}px`}
              color={`${theme.colors.primary}`}
            >
              Destination City
            </Text>
            <Text size={`${theme.font.size.xxl}px`}>
              {item?.destination_city}
            </Text>
          </FlexColumn>
          <FlexColumn gap="4px">
            <Text
              size={`${theme.font.size.xxl}px`}
              color={`${theme.colors.primary}`}
            >
              Destination Country
            </Text>
            <Text size={`${theme.font.size.xxl}px`}>
              {item?.destination_country}
            </Text>
          </FlexColumn>
          <FlexColumn gap="4px">
            <Text
              size={`${theme.font.size.xxl}px`}
              color={`${theme.colors.primary}`}
            >
              Destination Postal Code
            </Text>
            <Text size={`${theme.font.size.xxl}px`}>
              {item?.destination_postal_code}
            </Text>
          </FlexColumn>
          <FlexColumn gap="4px">
            <Text
              size={`${theme.font.size.xxl}px`}
              color={`${theme.colors.primary}`}
            >
              Destination Postal State
            </Text>
            <Text size={`${theme.font.size.xxl}px`}>
              {item?.destination_state}
            </Text>
          </FlexColumn>
        </FlexColumn>
        <FlexColumn dimensions={{ flex: 1 }} justifyContent="space-between">
          <FlexColumn>
            <Text
              size={`${theme.font.size.xxl}px`}
              color={`${theme.colors.primary}`}
            >
              Proposed Price
            </Text>
            <Text size={`${theme.font.size.xxl}px`}>
              {item?.events_map?.latest_price?.pricing?.price} $
            </Text>
          </FlexColumn>
          <FlexColumn gap="8px">
            <FlexColumn gap="4px">
              <Text
                size={`${theme.font.size.xxl}px`}
                color={`${theme.colors.primary}`}
              >
                Vehicle Type and Condition
              </Text>
              <Text size={`${theme.font.size.xxl}px`}>
                {item?.vehicles?.[0]?.vehicle_type},{" "}
                {item?.vehicles?.[0]?.vehicle_inop ===
                OperableType?.inoperable ? (
                  <>Inoperable</>
                ) : (
                  <>Operable</>
                )}
              </Text>
            </FlexColumn>
            <FlexColumn gap="4px">
              <Text
                size={`${theme.font.size.xxl}px`}
                color={`${theme.colors.primary}`}
              >
                Vehicle
              </Text>
              <Text size={`${theme.font.size.xxl}px`}>
                {item?.vehicles?.[0]?.vehicle_make}{" "}
                {item?.vehicles?.[0]?.vehicle_model}{" "}
                {item?.vehicles?.[0]?.vehicle_model_year}
              </Text>
            </FlexColumn>
          </FlexColumn>
        </FlexColumn>
        <FlexColumn dimensions={{ flex: 1 }} justifyContent="space-between">
          {(item?.notes ?? []).length > 0 && (
            <FlexColumn gap="8px">
              <FlexColumn
                dimensions={{ maxHeight: "250px" }}
                gap="16px"
                overflow={{
                  x: "hidden",
                  y: "scroll",
                }}
              >
                {item?.notes?.map((note: any) => (
                  <FlexColumn
                    gap="8px"
                    dimensions={{ width: "100%" }}
                    border={{ radius: "6px" }}
                    background={{
                      color: `${colors.blue.light}`,
                    }}
                    style={{
                      boxShadow: "inset 0px -2px 4px rgba(0, 0, 0, 0.25)",
                    }}
                    padding={{
                      top: "16px",
                      bottom: "16px",
                      left: "8px",
                      right: "8px",
                    }}
                  >
                    <Text
                      size={`${theme.font.size.x}px`}
                      color={`${theme.colors.primary}`}
                    >
                      {note?.agent?.first_name} {note?.agent?.last_name}
                    </Text>
                    <Text size={`${theme.font.size.xxl}px`}>
                      {note?.content}
                    </Text>
                    <div
                      style={{
                        height: "1px",
                        width: "100%",
                        backgroundColor: "#C7E7FF",
                      }}
                    />
                    <Text
                      size={`${theme.font.size.x}px`}
                      color={`${theme.colors.primary}`}
                    >
                      {toReadableDate(note?.created_at as Date)}
                    </Text>
                  </FlexColumn>
                ))}
              </FlexColumn>
            </FlexColumn>
          )}
        </FlexColumn>
      </FlexRow>
    </FlexRow>
  );
};
