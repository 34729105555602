import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth, useModalProvider } from "../../../../contexts";
import { User } from "../../../../models/user";
import {
  Scroll,
  Icon,
  IconTypes,
  Select,
  Toggle,
  BaseInput,
} from "../../../../shared/components";
import {
  HttpAdminGetUsersResponse,
  httpAdminGetUsers,
  httpAdminDeactivateUser,
  httpAdminActivateUser,
  httpAdminDeleteUser,
} from "../../../../apis/admin/user";
import {
  FlexRow,
  Badge,
  FlexContainer,
  FlexColumn,
  Button,
  Text,
} from "../../../../shared/styled";
import { theme, colors } from "../../../../constants";
import { UpdateUserAuthModal } from "./modals";
import { TableHeader } from "../../../../constants/table/TableHeader";
import { TableFooter } from "../../../../constants/table/TableFooter";
import { PaginationStyling } from "../../../../constants/table/tools/PaginationStyling";
import { Table } from "../../../../shared/components/Table";
import { TableStyling } from "../../../../constants/table/TableStyling";
import { TableSchema } from "./TableSchema";

export const Users = () => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModalProvider();

  const [data, setData] = useState<User[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const [search, setSearch] = useState<string>("");
  const [order, setOrder] = useState<string>("");
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [total, setTotal] = useState<number>();

  const fetch = async (reset = false) => {
    setLoading(true);
    try {
      const response = await httpAdminGetUsers(
        search,
        offset,
        limit,
        order.split(",")
      );

      if (reset)
        setData((response?.data as HttpAdminGetUsersResponse)?.results);
      setTotal((response?.data as HttpAdminGetUsersResponse)?.count || 0);
    } catch (err) {}
    setLoading(false);
  };

  const toggleStatus = useCallback(
    async (id: number | string, value: boolean) => {
      if (isLoading) return;

      (value ? httpAdminActivateUser : httpAdminDeactivateUser)(id)
        .then((_) => {
          // TODO Add Notifications
          fetch(true);
        })
        .catch((err) => {
          console.log(err);
          // TODO Add Notifications
        })
        .finally(() => setLoading(false));
    },
    [isLoading]
  );

  const deleteUser = useCallback(
    async (id: number) => {
      if (isLoading) return;
      setLoading(true);
      httpAdminDeleteUser(id)
        .then((_) => {
          // TODO Add toast notification
          fetch(true);
        })
        .catch((err) => {
          // TODO Add toast notification
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [isLoading]
  );

  useEffect(() => {
    fetch(true);
  }, [order, limit, offset]);

  return (
    <>
      <Scroll wrapperStyle={{ dimensions: { flex: 1 } }}>
        <FlexContainer
          dimensions={{ width: "100%" }}
          padding={{ left: "32px", right: "32px", top: "32px" }}
        >
          <Table<User>
            headerComponent={
              <TableHeader>
                <FlexColumn
                alignItems="flex-end"
                gap="5px"
                padding={{ top: "16px", bottom: "16px" }}
                dimensions={{ width: "20%" }}
              >
                <BaseInput
                  placeholder="Search..."
                  value={search}
                  onChange={async (e) => {
                    setSearch(e.target.value);
                    await fetch(true);
                  }}
                  dimensions={{ width: "100%", height: "32px" }}
                />
                <Button
                  background={colors.white.clean}
                  border={{
                    color: theme.colors.disabled,
                    width: "1px",
                    radius: "8px",
                  }}
                  justifyContent="center"
                  alignItems="center"
                  padding={{ left: "16px", right: "16px" }}
                  onClick={() => navigate("/admin/manage")}
                  dimensions={{ width: "fit-content", height: "32px" }}
                >
                  <FlexRow gap="10px">
                    <Icon
                      type={IconTypes.addPerson}
                      color={theme.colors.secondary}
                      size="16px"
                    />
                    <Text
                      color={theme.colors.secondary}
                      size="14px"
                      weight={500}
                    >
                      Add User
                    </Text>
                  </FlexRow>
                </Button>
              </FlexColumn>
              </TableHeader>
            }
            footerComponent={
              <TableFooter disabled={isLoading}>
                <PaginationStyling
                  breakLabel="..."
                  nextLabel="next"
                  onPageChange={({ selected }) => setOffset(selected * limit)}
                  pageRangeDisplayed={3}
                  pageCount={
                    total
                      ? Math.round(total / limit) +
                        (total % limit === 0 ? 1 : 0)
                      : 1
                  }
                  previousLabel="previous"
                  initialPage={0}
                  renderOnZeroPageCount={null}
                />
              </TableFooter>
            }
            order={{
              setOrder: (value) => setOrder(value ?? ""),
              currentOrder: order ?? "",
            }}
            style={TableStyling}
            pagination={{
              limit,
              setLimit,
              offset,
              setOffset,
              total: total ?? 0,
              itemsPerPageOptions: [10, 20, 50, 100],
            }}
            data={data}
            columns={TableSchema({
              toggleStatus,
              navigate,
              openModal,
              closeModal,
              deleteUser,
            })}
          />
        </FlexContainer>
      </Scroll>
    </>
  );
};
