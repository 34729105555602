import { FlexContainer } from "../../shared/styled";
import { ReactNode } from "react";

export const TableFooter = ({
  children,
  disabled = false,
}: {
  children?: ReactNode;
  disabled?: boolean;
}) => {
  return (
    <FlexContainer
      dimensions={{ width: "100%", height: "50px" }}
      background={"#E4ECFB"}
      padding={{ left: "50px", right: "50px" }}
      style={{ pointerEvents: disabled ? "none" : undefined }}
    >
      {children}
    </FlexContainer>
  );
};
