import { useCallback, useMemo, useRef } from "react";

import { FlexContainer } from "../../styled";

import { TableProps } from "./types/TableProps";
import { CurrentOrderType } from "./types/CurrentOrder";
import {
  CHARSET_CLETTERS,
  CHARSET_LLETTERS,
  CHARSET_NUMBERS,
  randomString,
} from "../../../utilities/random";

const CHARSET = `${CHARSET_NUMBERS}${CHARSET_LLETTERS}${CHARSET_CLETTERS}`;

const CaretUp = () => <>&#9650;</>;
const CaretDown = () => <>&#9660;</>;
const OrderSymbols = {
  ASC: CaretUp,
  DESC: CaretDown,
};

export const Table = <DataType,>({
  order,
  columns,
  style,
  headerComponent,
  footerComponent,
  data,
  onRowClick,
  extraRows,
  onCellClick,
}: TableProps<DataType>) => {
  const tableId = useRef<string>(randomString(CHARSET, 16));
  const currentOrder = useMemo<CurrentOrderType>(
    () =>
      (order?.currentOrder ?? "")
        .split(",")
        .filter((key) => 0 < key.length)
        .reduce<CurrentOrderType>((currentObject, key) => {
          const isDesc = key[0] === "-";
          const attrName = isDesc ? key.slice(1) : key;

          return {
            ...currentObject,
            [attrName]: isDesc ? "DESC" : "ASC",
          };
        }, {}),
    [order]
  );

  const applyOrder = useCallback(
    (attribute: string) => {
      if (!order?.setOrder) return;
      const currentOrderTmp = currentOrder;

      if (attribute in currentOrderTmp) {
        if (currentOrderTmp[attribute] === "DESC") {
          delete currentOrderTmp[attribute];
        } else {
          currentOrderTmp[attribute] = "DESC";
        }
      } else {
        currentOrderTmp[attribute] = "ASC";
      }

      const currentOrderQueryString = Object.keys(currentOrderTmp)
        .map((orderKey) =>
          currentOrder[orderKey] === "ASC" ? orderKey : `-${orderKey}`
        )
        .join(",");

      order?.setOrder(currentOrderQueryString);
    },
    [currentOrder, order]
  );

  const headerFields = useMemo(
    () =>
      columns.map((column) => ({
        label: column.label,
        dimensions: column.styling?.dimensions ?? { flex: 1 },
        gap: column.styling?.gap,
        direction: column.styling?.flexDirection,
        orderAttribute: column.orderByAttribute,
      })),
    [columns]
  );

  return (
    <FlexContainer
      dimensions={{ width: "100%" }}
      gap="10px"
      background={{ color: "white" }}
      {...style?.wrapper}
      flexDirection="column"
    >
      {headerComponent}
      <FlexContainer
        {...style?.header}
        dimensions={{ width: "100%", ...style?.header?.dimensions }}
        flexDirection="row"
      >
        {headerFields.map(({ label, orderAttribute, ...styling }, index) => {
          const OrderByComponent =
            orderAttribute &&
            OrderSymbols[currentOrder[orderAttribute?.toString()]];

          return (
            <FlexContainer
              {...style?.headerCell}
              {...styling}
              key={`${tableId}header${index}`}
              onClick={() => {
                if (!orderAttribute) return;
                applyOrder(orderAttribute.toString());
              }}
            >
              {label}
              {OrderByComponent && <OrderByComponent />}
            </FlexContainer>
          );
        })}
      </FlexContainer>
      <FlexContainer {...style?.body} flexDirection="column">
        {data.map((item, index) => (
          <>
            <FlexContainer
              {...style?.row}
              key={`${tableId}row${index}`}
              flexDirection="row"
              onClick={() => onRowClick && onRowClick(item)}
            >
              {columns.map((column, index) => {
                const renderContent = column.render
                  ? column.render(item)
                  : null;
                const rawValue = column.attribute
                  ? `${item[column.attribute]}`
                  : null;

                return (
                  <FlexContainer
                    {...style?.cell}
                    key={`${tableId}cell${index}`}
                    dimensions={column?.styling?.dimensions ?? { flex: 1 }}
                    onClick={() => {
                      column?.onClick && column?.onClick(item);
                      onCellClick && onCellClick(item);
                    }}
                  >
                    {renderContent ?? rawValue}
                  </FlexContainer>
                );
              })}
            </FlexContainer>
            {extraRows?.map((extraRow) => extraRow.render(item))}
          </>
        ))}
      </FlexContainer>
      {footerComponent}
    </FlexContainer>
  );
};
