import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useModalProvider } from "../../../../../contexts";
import { getLastMonday } from "../utilities";
import { DateRangeValue } from "../../../../../shared/components/DateRange/types";
import { AgentStatistics } from "../../../../../models/statistics";
import { theme } from "../../../../../constants";
import {
  Button,
  ButtonTypes,
  FlexColumn,
  FlexItem,
  FlexRow,
  Text,
  TitleXL,
} from "../../../../../shared/styled";
import { DateRangeFilterModal } from "../../../../../modals";
import {
  Icon,
  IconTypes,
  SelectOption,
} from "../../../../../shared/components";
import {
  toQueryParamDateTime,
  toReadableDateOnly,
} from "../../../../../utilities/datetime";
import {
  HttpAdminGetAgentStatisticsResponse,
  httpAdminGetAgentStatistics,
  httpGetProviders,
} from "../../../../../apis/admin";
import toast from "react-hot-toast";
import { SearchSelect } from "../../../../../shared/components/Select/SearchSelect";
import { Provider } from "../../../../../models/provider";
import { Table } from "../../../../../shared/components/Table";
import { TableHeader } from "../../../../../constants/table/TableHeader";
import { TableFooter } from "../../../../../constants/table/TableFooter";
import { StatisticsTableStyling } from "./TableStyling";
import { AgentStatisticsTableSchema } from "./schemes/AgentTableSchema";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PaginationStyling } from "../../../../../constants/table/tools/PaginationStyling";

export const AgentTable = () => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModalProvider();

  const [searchParams] = useSearchParams();
  const [isLoading, setLoading] = useState<boolean>(false);

  const [order, setOrder] = useState<string>();

  const [limit, setlimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [total, settotal] = useState<number>();

  const initialPage = useRef<number>(Number(searchParams.get("page") ?? "0"));

  const [createdAtRange, setCreatedAtRange] = useState<DateRangeValue>({
    start: getLastMonday(),
    end: new Date(),
  });
  const [providerId, setProviderId] = useState<SelectOption | undefined>();

  const [data, setData] = useState<AgentStatistics[]>();
  const [providers, setProviders] = useState<Provider[]>();

  const fetchProviders = useCallback(async () => {
    try {
      const { data: fetchedProviders } = await httpGetProviders();
      setProviders(fetchedProviders as Provider[]);
    } catch (err) {
      toast.error("Failed to fetch providers list", {
        style: {
          border: `1px solid ${theme.colors.danger}`,
          padding: "16px",
          boxShadow: "none",
          borderRadius: "24px",
        },
        iconTheme: {
          primary: `${theme.colors.danger}`,
          secondary: `${theme.colors.clean}`,
        },
      });
      console.error(err);
    }
  }, []);

  const fetchData = useCallback(async () => {
    setLoading(true);
    if (!createdAtRange?.start || !createdAtRange?.end) {
      setLoading(false);
      return;
    }
    try {
      const agentResponse = await httpAdminGetAgentStatistics(
        offset,
        limit,
        order?.split(","),
        createdAtRange?.start && createdAtRange?.end
          ? toQueryParamDateTime(createdAtRange.start)
          : undefined,
        createdAtRange?.start && createdAtRange?.end
          ? toQueryParamDateTime(createdAtRange.end, true)
          : undefined,
        providerId?.value
      );
      setData(
        (
          agentResponse.data as HttpAdminGetAgentStatisticsResponse
        )?.results.filter(
          (statistic) =>
            statistic.total_leads !== undefined && statistic.total_leads > 0
        )
      );
      settotal(
        (agentResponse?.data as HttpAdminGetAgentStatisticsResponse)?.count
      );
      setLoading(false);
    } catch (error) {
      toast.error("Error fetching Agents", {
        style: {
          border: `1px solid ${theme.colors.danger}`,
          padding: "16px",
          boxShadow: "none",
          borderRadius: "24px",
        },
        iconTheme: {
          primary: `${theme.colors.danger}`,
          secondary: `${theme.colors.clean}`,
        },
      });
      console.error(error);
    }
  }, [createdAtRange, providerId, offset, limit, order]);

  useEffect(() => {
    fetchProviders();
  }, []);

  useEffect(() => {
    fetchData();
  }, [order, limit, offset, createdAtRange, providerId]);

  const providerOptions = useMemo(
    () =>
      (providers ?? [])?.map((provider) => ({
        value: provider.id,
        label: (
          <FlexRow dimensions={{ width: "100%" }}>
            <Text color={theme.font.color}>{provider.name}</Text>
          </FlexRow>
        ),
        searchValue: provider.name,
      })),
    [providers]
  );

  const createdAtRangeQuery = useMemo(() => {
    if (!createdAtRange?.start || !createdAtRange?.end) return "";
    return `created_at__gte=${toQueryParamDateTime(
      createdAtRange?.start
    )}&created_at__lte=${toQueryParamDateTime(createdAtRange?.end)}`;
  }, [createdAtRange]);


  return (
    <>
      <Table<AgentStatistics>
        order={{
          currentOrder: order ?? "",
          setOrder: (freshOrder) => setOrder(freshOrder ?? ""),
        }}
        headerComponent={
          <TableHeader>
            <FlexColumn dimensions={{ width: "100%" }}>
              <FlexRow justifyContent="flex-start">
                <TitleXL>AGENT STATISTICS</TitleXL>
              </FlexRow>
              <FlexRow gap="10px" justifyContent="flex-end">
                <SearchSelect
                  options={providerOptions}
                  value={providerId}
                  onChange={(value) => setProviderId(value ? value : undefined)}
                  placeholder="Lead Provider"
                  isClearable={true}
                  isSearchable={true}
                  transparent={true}
                  iconColor={theme.font.color}
                />

                <Button
                  styleType={ButtonTypes?.primary}
                  dimensions={{ height: "46px" }}
                  disabled={isLoading}
                  onClick={() =>
                    openModal(
                      "createdAtAgentStatisticsModal",
                      DateRangeFilterModal,
                      {
                        setValue: setCreatedAtRange,
                        value: createdAtRange,
                        close: () =>
                          closeModal("createdAtAgentStatisticsModal"),
                        title: <TitleXL>Created At Range</TitleXL>,
                      }
                    )
                  }
                >
                  <FlexRow
                    justifyContent="space-between"
                    alignItems="center"
                    gap="5px"
                  >
                    <Icon
                      type={IconTypes?.calendar}
                      size="20px"
                      color={theme?.colors?.clean}
                    />
                    <FlexItem dimensions={{ flex: 1 }}>
                      <Text color={theme?.colors?.clean} align="center">
                        {(!createdAtRange?.start || !createdAtRange?.end) && (
                          <>Created At Range</>
                        )}
                        {createdAtRange?.start && createdAtRange?.end && (
                          <>
                            {toReadableDateOnly(createdAtRange?.start)} -{" "}
                            {toReadableDateOnly(createdAtRange?.end)}
                          </>
                        )}
                      </Text>
                    </FlexItem>
                  </FlexRow>
                </Button>
              </FlexRow>
            </FlexColumn>
          </TableHeader>
        }
        footerComponent={
          <TableFooter disabled={isLoading}>
            <FlexColumn alignContent="center" justifyContent="center">
              <Text>Total: {total}</Text>
            </FlexColumn>
            <PaginationStyling
              breakLabel="..."
              nextLabel="next"
              onPageChange={({ selected }) => {
                setOffset(selected * limit);
              }}
              pageRangeDisplayed={3}
              pageCount={
                total
                  ? Math.round(total / limit) + (total % limit === 0 ? 1 : 0)
                  : 1
              }
              previousLabel="previous"
              initialPage={initialPage.current}
              renderOnZeroPageCount={null}
            />
          </TableFooter>
        }
        style={StatisticsTableStyling}
        data={data ?? []}
        columns={AgentStatisticsTableSchema(navigate, createdAtRangeQuery)}
        isLoading={isLoading}
      />
    </>
  );
};
