import { useEffect, useMemo, useState } from "react";
import {
  HttpAdminUpdateTemplate,
  httpAdminRetriveTemplate,
  httpAdminUpdateTemplate,
} from "../../../../apis/admin";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { httpAdminCreateTemplateList } from "../../../../apis/admin/template/adminCreateTemplate";
import { Toaster } from "react-hot-toast";
import {
  Button,
  FlexColumn,
  FlexContainer,
  FlexRow,
  Text,
  Title,
  TitleXXL,
} from "../../../../shared/styled";
import { Loading } from "../../../../shared/components";
import { colors, theme } from "../../../../constants";
import {
  StyledBaseInput,
  StyledBaseTextArea,
} from "../../../../shared/components/Input/StyledBaseInput";
import { EmailTemplater } from "../../../../shared/components/EmailTemplater";
import { TEMPLATE_TYPE_EMAIL } from "../../../../models/template";

interface FormData extends HttpAdminUpdateTemplate {}

export const ManageTemplate = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const isCreate = useMemo(() => (id && `${id}` === "new") || !id, [id]);
  const [isLoading, setLoading] = useState<boolean>(!isCreate);

  const [isTemplateEditorFullScreen, setTemplateEditorFullScreen] =
    useState<boolean>(false);

  const { register, handleSubmit, setValue, getValues } = useForm<FormData>();
  const fetch = async (id: string | number) => {
    setLoading(true);
    try {
      const { data } = await httpAdminRetriveTemplate(id);
      setValue("content", data?.content);
      setValue("type", data?.type);
      setValue("cc", data?.cc);
      setValue("name", data?.name);
      setValue("subject", data?.subject);
      setValue("reply_to", data?.reply_to);
    } catch (err) {
      err;
      // TODO Add notification
    }
    setLoading(false);
  };

  const create: SubmitHandler<FormData> = async (data) => {
    setLoading(true);
    try {
      await httpAdminCreateTemplateList({
        ...data,
        html_content: JSON.parse(data.content as string).html,
        type: TEMPLATE_TYPE_EMAIL,
        is_active: true
      });
      navigate("/admin/templates");
      // TODO Add Notifications
    } catch (err) {
      err;
      // TODO Add Notifications
    }
    setLoading(false);
  };

  const update: SubmitHandler<FormData> = async (data) => {
    if (!id) return;
    setLoading(true);
    try {
      await httpAdminUpdateTemplate(id, {
        ...data,
        html_content: JSON.parse(data.content as string).html,
      });
      navigate("/admin/templates");
      // TODO Add Notifications
    } catch (err) {
      err;
      // TODO Add Notifications
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!isCreate) fetch(id as string | number);
  }, [id]);

  const defaultContent = (() => {
    const emailDesign = getValues("content");
    if (!emailDesign) return;

    return JSON.parse(emailDesign).design;
  })();

  return (
    <>
      <Toaster position="bottom-left" reverseOrder={false} />
      <FlexContainer
        flexDirection="column"
        dimensions={{ width: "100%", height: "100%" }}
        padding={{ left: "32px", right: "32px", top: "64px" }}
        overflow={{ y: "auto" }}
      >
        {isLoading && <Loading />}
        {!isLoading && (
          <form onSubmit={handleSubmit(isCreate ? create : update)}>
            <FlexColumn
              dimensions={{ width: "100%" }}
              gap="10px"
              background={{ color: "white" }}
              style={{
                boxShadow: ` 0px 4px 4px${theme.shadow.color}`,
                borderRadius: "16px 16px 16px 16px",
              }}
            >
              <FlexRow
                dimensions={{ width: "100%" }}
                padding={{ bottom: "16px" }}
              >
                <FlexRow
                  style={{ borderRadius: "16px 16px 0px 0px" }}
                  justifyContent="flex-start"
                  alignItems="center"
                  gap="20px"
                  dimensions={{ height: "72px", width: "100%" }}
                  background={{ color: "#E4ECFB" }}
                  padding={{
                    top: "4px",
                    bottom: "4px",
                    left: "40px",
                    right: "40px",
                  }}
                >
                  <TitleXXL text-align="center" color={theme.colors.primary}>
                    {isCreate ? <>Create Template</> : <>Edit Template</>}
                  </TitleXXL>
                </FlexRow>
              </FlexRow>
              <FlexColumn gap="10px">
                <FlexRow
                  dimensions={{ width: "100%", height: "100%" }}
                  padding={{ top: "16px", left: "40px", right: "40px" }}
                  gap="100px"
                >
                  <FlexColumn
                    gap="10px"
                    dimensions={{ width: "100%", height: "100%" }}
                  >
                    <Title text-align="left" color={theme.font.color}>
                      Name
                    </Title>
                    <StyledBaseInput
                      type="text"
                      dimensions={{ width: "100%", height: "24px" }}
                      placeholder="Enter template name..."
                      {...register("name", { required: true })}
                    />
                  </FlexColumn>
                  <FlexColumn
                    gap="10px"
                    dimensions={{ width: "100%", height: "100%" }}
                  >
                    <Title text-align="left" color={theme.font.color}>
                      Reply To
                    </Title>
                    <StyledBaseInput
                      type="text"
                      dimensions={{ width: "100%", height: "24px" }}
                      placeholder="Enter reply to email address..."
                      {...register("reply_to", { required: true })}
                    />
                  </FlexColumn>
                </FlexRow>

                <FlexRow
                  dimensions={{ width: "100%", height: "100%" }}
                  padding={{ top: "16px", left: "40px", right: "40px" }}
                  gap="100px"
                >
                  <FlexColumn
                    gap="10px"
                    dimensions={{ width: "100%", height: "100%" }}
                  >
                    <Title text-align="left" color={theme.font.color}>
                      Subject
                    </Title>
                    <StyledBaseInput
                      type="text"
                      dimensions={{ width: "100%", height: "24px" }}
                      placeholder="Enter mail subject..."
                      {...register("subject", { required: true })}
                    />
                  </FlexColumn>
                </FlexRow>

                <FlexRow
                  dimensions={{ width: "100%", height: "100%" }}
                  padding={{ top: "16px", left: "40px", right: "40px" }}
                  gap="100px"
                >
                  <FlexColumn
                    gap="10px"
                    dimensions={{ width: "100%", height: "100%" }}
                  >
                    <Title text-align="left" color={theme.font.color}>
                      CC List
                    </Title>
                    <StyledBaseTextArea
                      dimensions={{ width: "100%", height: "128px" }}
                      placeholder="Enter cc list..."
                      {...register("cc", { required: true })}
                    />
                  </FlexColumn>
                  {/* 

                    <FlexColumn
                      gap="10px"
                      dimensions={{ width: "100%", height: "100%" }}
                    >
                      <Title text-align="left" color={theme.font.color}>
                        Tag List
                      </Title>
                      <StyledBaseTextArea
                        dimensions={{ width: "100%", height: "24px" }}
                        placeholder="Enter tag list..."
                        {...register("cc", { required: true })}
                      />
                    </FlexColumn> 
                  */}
                </FlexRow>

                <FlexColumn
                  background={
                    isTemplateEditorFullScreen ? colors.white.clean : "unset"
                  }
                  dimensions={
                    !isTemplateEditorFullScreen
                      ? { width: "100%", height: "100%" }
                      : { width: "100vw", height: "100vh" }
                  }
                  border={{ bottom: "1px solid rgb(212, 212, 212)" }}
                  style={{
                    position: !isTemplateEditorFullScreen
                      ? "relative"
                      : "fixed",
                    top: "0",
                    left: "0",
                  }}
                >
                  <FlexRow
                    justifyContent="flex-end"
                    padding={"10px"}
                    border={{
                      bottom: "1px solid rgb(212, 212, 212)",
                    }}
                  >
                    <Button
                      justifyContent="center"
                      alignItems="center"
                      dimensions={{ width: "auto", height: "40px" }}
                      background={{ color: theme.colors.primary }}
                      border={{ radius: "8px" }}
                      type="button"
                      onClick={() =>
                        setTemplateEditorFullScreen(!isTemplateEditorFullScreen)
                      }
                    >
                      <Text color="white">
                        {!isTemplateEditorFullScreen ? (
                          <>Full Screen</>
                        ) : (
                          <>Exit Full Screen</>
                        )}
                      </Text>
                    </Button>
                  </FlexRow>

                  <EmailTemplater
                    setValue={(value) =>
                      setValue("content", value)
                    }
                    value={defaultContent}
                  />
                </FlexColumn>
                <FlexRow
                  justifyContent="flex-end"
                  gap="32px"
                  padding={{ left: "40px", right: "40px", bottom: "40px" }}
                >
                  <Button
                    justifyContent="center"
                    alignItems="center"
                    dimensions={{ width: "auto", height: "40px" }}
                    background={{ color: theme.colors.danger }}
                    border={{ radius: "8px" }}
                    onClick={() => navigate("/admin/templates")}
                  >
                    <Text color="white">Cancel</Text>
                  </Button>
                  <Button
                    justifyContent="center"
                    alignItems="center"
                    dimensions={{ width: "auto", height: "40px" }}
                    background={{ color: theme.colors.primary }}
                    border={{ radius: "8px" }}
                    type="submit"
                  >
                    <Text color="white">Submit</Text>
                  </Button>
                </FlexRow>
              </FlexColumn>
            </FlexColumn>
          </form>
        )}
      </FlexContainer>
    </>
  );
};
