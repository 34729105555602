import { theme } from "../../../../../../constants";
import { FlexColumn } from "../../../../../../shared/styled";

interface PrecentagePerItemProps {
  total?: number;
  value?: number;
}

export const PrecentagePerItem = ({ total, value }: PrecentagePerItemProps) => (
  <FlexColumn
    justifyContent="center"
    alignItems="center"
    alignContent="center"
    gap={"4px"}
  >
    <div>{value ?? <>-</>}</div>
    {value && total ? (
      <div
        style={{
          backgroundColor: theme.colors.secondary,
          width: "100%",
          height: "1px",
        }}
      />
    ) : (
      <></>
    )}
    <div>
      {value && total ? `${((value / total) * 100).toFixed(2)}%` : <> - </>}
    </div>
  </FlexColumn>
);
