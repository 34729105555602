import { css } from "styled-components";

import { theme } from "../theme";
import { TablePropsStyle } from "../../shared/components/Table/types/TableStyleProps";

export const TableStyling: TablePropsStyle = {
  wrapper: {
    dimensions: {
      width: "100%",
    },
    css: css`
      border-radius: 24px;
      box-shadow: rgb(182, 182, 182) 0px 4px 4px 0px;
      overflow: hidden;
    `,
  },
  header: {
    justifyContent: "space-between",
    alignItems: "center",
    css: css`
      color: ${theme.font.color};
      font-size: ${theme.font.size.xl}px;
      text-transform: uppercase;
      font-weight: bold;
      padding: 32px;
    `,
    dimensions: {
      minHeight: "30px",
    },
  },
  body: {
    gap: "10px",
    css: css`
      padding: 32px;
    `,
  },
  row: {
    justifyContent: "space-between",
    dimensions: { height: "64px" },
    alignItems: "center",
    css: css`
      cursor: pointer;
      &:hover {
        box-shadow: rgb(182, 182, 182) 0px 0px 10px 4px;
        border-radius: 8px;
      }
    `,
  },
  cell: {
    alignItems: "center",
    css: css`
      &:first-child {
        justify-content: flex-end;
      }
    `,
  },
  headerCell: {
    alignItems: "center",
    css: css`
      &:first-child {
        justify-content: flex-end;
      }
    `,
  },
};
