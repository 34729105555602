import { useEffect, useState } from "react";
import { Template } from "../../../../models/template";
import {
  BaseInput,
  Icon,
  IconTypes,
  Scroll,
  Toggle,
} from "../../../../shared/components";
import {
  Button,
  FlexColumn,
  FlexContainer,
  FlexRow,
  Text,
} from "../../../../shared/styled";
// import { Table } from "../components/Table";
import {
  HttpPaginatedTemplatesListResponseFilters,
  httpAdminTemplateList,
  httpAdminUpdateTemplate,
} from "../../../../apis/admin";
import { colors, theme } from "../../../../constants";
import { useNavigate } from "react-router-dom";
import { Table } from "../../../../shared/components/Table";
import { TableStyling } from "../../../../constants/table/TableStyling";
import { TableHeader } from "../../../../constants/table/TableHeader";
import { TableFooter } from "../../../../constants/table/TableFooter";
import { TableSchema } from "./TableSchema";
import ReactPaginate from "react-paginate";
import { PaginationStyling } from "../../../../constants/table/tools/PaginationStyling";

export const Templates = () => {
  const navigate = useNavigate();

  const [data, setData] = useState<Template[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] =
    useState<HttpPaginatedTemplatesListResponseFilters>({});
  const [order, setOrder] = useState<string>("");
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [total, setTotal] = useState<number>();

  const fetch = async () => {
    setLoading(true);
    try {
      const response = await httpAdminTemplateList(
        search,
        offset,
        limit,
        (order ?? "").split(","),
        filters
      );
      setData(response?.data?.results);
      setTotal(response?.data?.count);
    } catch (err) {}
    setLoading(false);
  };

  const toggleStatus = async (id: number | string, value: boolean) => {
    setLoading(true);
    try {
      const response = await httpAdminUpdateTemplate(id, { is_active: value });
      fetch();
      // TODO Add Notifications
    } catch (err) {
      err;
      // TODO Add Notifications
    }
    setLoading(false);
  };

  useEffect(() => {
    fetch();
  }, [order, limit, offset, filters, search]);

  return (
    <Scroll wrapperStyle={{ dimensions: { flex: 1 } }}>
      <FlexContainer
        dimensions={{ width: "100%" }}
        padding={{ left: "32px", right: "32px", top: "32px" }}
      >
        <Table<Template>
          headerComponent={
            <TableHeader>
              <Button
                background={colors.white.clean}
                border={{
                  color: theme.colors.disabled,
                  width: "1px",
                  radius: "8px",
                }}
                justifyContent="center"
                alignItems="center"
                padding={{ left: "16px", right: "16px" }}
                onClick={() => navigate("/admin/templates/new")}
                dimensions={{ width: "fit-content", height: "32px" }}
              >
                <FlexRow gap="10px">
                  <Icon
                    type={IconTypes.plus}
                    color={theme.colors.secondary}
                    size="16px"
                  />
                  <Text color={theme.colors.secondary} size="14px" weight={500}>
                    Add Template
                  </Text>
                </FlexRow>
              </Button>
            </TableHeader>
          }
          footerComponent={
            <TableFooter>
              <PaginationStyling
                breakLabel="..."
                nextLabel="next"
                onPageChange={({selected}) => setOffset(selected*limit)}
                pageRangeDisplayed={3}
                pageCount={total ? Math.round(total / limit) + (total % limit === 0 ? 1 : 0) : 1}
                previousLabel="previous"
                initialPage={0}
                renderOnZeroPageCount={null}
              />
            </TableFooter>
          }
          order={{
            setOrder: (value) => setOrder(value ?? ""),
            currentOrder: order ?? "",
          }}
          style={TableStyling}
          pagination={{
            limit,
            setLimit,
            offset,
            setOffset,
            total: total ?? 0,
            itemsPerPageOptions: [10, 20, 50, 100],
          }}
          data={data}
          columns={TableSchema({ toggleStatus, navigate })}
        />
      </FlexContainer>
    </Scroll>
  );
};
