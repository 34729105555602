import { NavigateFunction } from "react-router-dom";
import { LeadProviderStatistics } from "../../../../../../models/statistics";
import { TableProps } from "../../../../../../shared/components/Table/types/TableProps";
import { PrecentagePerItem } from "../components/PrecentagePerItem";
import { Priorities } from "../../../../../../models/lead/priorities";
import { DateRangeValue } from "../../../../../../shared/components/DateRange/types";

export const LeadProviderStatisticsTableSchema = (
  navigate: NavigateFunction,
  createdAtRangeQuery: string
): TableProps<LeadProviderStatistics>["columns"] => [
  {
    label: "No",
    styling: { dimensions: { maxWidth: "100px", width: "100px" } },
    render: (item) =>
      ![undefined, 0].includes(item?.lead_provider?.index) ? (
        item?.lead_provider?.index
      ) : (
        <> - </>
      ),
  },
  {
    label: "Provider Name",
    styling: { dimensions: { maxWidth: "200px", width: "200px" } },
    render: (item) => item?.lead_provider?.name,
    onClick: (item) =>
      navigate(
        `/admin/leads?provider_id=${item?.lead_provider?.index}&${createdAtRangeQuery}`
      ),
  },
  {
    label: "Claimed",
    attribute: "total_leads",
    styling: { dimensions: { width: "150px", maxWidth: "150px" } },
    orderByAttribute: "total_leads",
    onClick: (item) => {
      if (item?.lead_provider?.name === "TOTAL") return;
      navigate(
        `/admin/leads?provider_id=${item?.lead_provider?.index}&${createdAtRangeQuery}`
      );
    },
  },
  {
    label: "Gray",
    attribute: "claimed_value",
    orderByAttribute: "claimed_value",
    styling: {
      dimensions: { maxWidth: "150px", width: "150px" },
    },
    render: (item) => (
      <PrecentagePerItem
        value={item?.claimed_value}
        total={item?.total_leads}
      />
    ),
    onClick: (item) =>
      navigate(
        `/admin/leads?provider_id=${item?.lead_provider?.index}&priority__in=${Priorities.default}&${createdAtRangeQuery}`
      ),
  },
  {
    label: "Bad",
    attribute: "bad_value",
    orderByAttribute: "bad_value",
    styling: {
      dimensions: { maxWidth: "150px", width: "150px" },
    },
    render: (item) => (
      <PrecentagePerItem value={item?.bad_value} total={item?.total_leads} />
    ),
    onClick: (item) =>
      navigate(
        `/admin/leads?provider_id=${item?.lead_provider?.index}&priority__in=${Priorities.bad_lead}&${createdAtRangeQuery}`
      ),
  },
  {
    label: "Future",
    attribute: "future_value",
    orderByAttribute: "future_value",
    styling: {
      dimensions: { maxWidth: "150px", width: "150px" },
    },
    render: (item) => (
      <PrecentagePerItem value={item?.future_value} total={item?.total_leads} />
    ),
    onClick: (item) =>
      navigate(
        `/admin/leads?provider_id=${item?.lead_provider?.index}&priority__in=${Priorities.future_lead}&${createdAtRangeQuery}`
      ),
  },
  {
    label: "Hot",
    attribute: "hot_value",
    orderByAttribute: "hot_value",
    styling: {
      dimensions: { maxWidth: "150px", width: "150px" },
    },
    render: (item) => (
      <PrecentagePerItem value={item?.hot_value} total={item?.total_leads} />
    ),
    onClick: (item) =>
      navigate(
        `/admin/leads?provider_id=${item?.lead_provider?.index}&priority__in=${Priorities.hot_lead}&${createdAtRangeQuery}`
      ),
  },
  {
    label: "Potential",
    attribute: "potential_value",
    orderByAttribute: "potential_value",
    styling: {
      dimensions: { maxWidth: "150px", width: "150px" },
    },
    render: (item) => (
      <PrecentagePerItem
        value={item?.potential_value}
        total={item?.total_leads}
      />
    ),
    onClick: (item) =>
      navigate(
        `/admin/leads?provider_id=${item?.lead_provider?.index}&priority__in=${Priorities.potential_lead}&${createdAtRangeQuery}`
      ),
  },
  {
    label: "Booked",
    attribute: "booked_value",
    orderByAttribute: "booked_value",
    styling: {
      dimensions: { maxWidth: "150px", width: "150px" },
    },
    render: (item) => (
      <PrecentagePerItem value={item?.booked_value} total={item?.total_leads} />
    ),
    onClick: (item) =>
      navigate(
        `/admin/leads?provider_id=${item?.lead_provider?.index}&priority__in=${Priorities.booked_lead}&${createdAtRangeQuery}`
      ),
  },
];
