import { useSearchParams } from "react-router-dom";

export const leadFilterHook = () => {
  const [searchParams] = useSearchParams();

  return {
    search: searchParams.get("search") ?? undefined,
    created_at__gte: searchParams.get("created_at__gte") ?? undefined,
    created_at__lte: searchParams.get("created_at__lte") ?? undefined,
    ship_date__gte: searchParams.get("ship_date__gte") ?? undefined,
    ship_date__lte: searchParams.get("ship_date__lte") ?? undefined,
    priority__in: searchParams.get("priority__in")
      ? searchParams
          .get("priority__in")
          ?.split(",")
          .map((item) => Number(item))
      : undefined,
    agent__id: searchParams.get("agent__id")
      ? Number(searchParams.get("agent__id"))
      : undefined,
    provider_id: searchParams.get("provider_id")
      ? Number(searchParams.get("provider_id"))
      : undefined,
  };
};
